<template>
    <Loader :show="loading" v-if="loading" />
    <div class="px-5 py-5 bg-gray" v-else>
        <div v-if="accessData">
            <MDBCardTitle v-html="content.title[storedLocale]"></MDBCardTitle>
            <div class="bg-white px-3 py-3 content-box" ref="contentBox" v-on:scroll="handleScroll">
                <div class="pb-2" v-html="content.description[storedLocale]"></div>
            </div>

            <MDBCardFooter class="px-0 d-flex justify-content-between pt-2 border--none">
                <div class="d-flex align-items-center ">
                    <div v-if="content.external_link[storedLocale]" class="ps-2">
                        <img src="@/assets/img/icons/wf-pdf.svg" alt="Download" />
                        <a :href="content.external_link[storedLocale]" target="_blank">
                            {{ $t("global.buttons.download") }}</a>
                    </div>
                </div>
                <div>
                    <MDBBtn class="d-flex justify-content-center align-items-center" color="danger"
                        :disabled="disableBtn" @click="accept()"><span>{{ $t("global.buttons.accept") }}</span>
                        <Loader :show="loading" :small="true" spinner="spinner.svg" :tiny="true" />
                    </MDBBtn>
                </div>
            </MDBCardFooter>
        </div>
    </div>
</template>

<script setup>
import {
    MDBBtn,
    MDBCardFooter, MDBCardTitle
} from "mdb-vue-ui-kit";
import Loader from "@/components/General/Loader/index.vue";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { TextPageService } from "@/services/textPage.service";
import { ProfileService } from "@/services/profile.service";

const disableBtn = ref(true)
const loading = ref(false)
const content = ref(null)
const contentBox = ref();
const store = useStore();
const user = computed(() => store.getters.getMe);
const accessData = computed(() => store.getters.getAccessData);
const storedLocale = computed(() => store.getters.getCurrentLocale);

const handleScroll = (event) => {
    if (
        Math.round(event.srcElement.offsetHeight + event.srcElement.scrollTop) +
        2 >=
        event.srcElement.scrollHeight
    ) {
        disableBtn.value = false;
    }
};

const accept = async () => {
    disableBtn.value = true
    loading.value = true
    // approve commitment letter

    const data = {
        id: user.value.id,
        document_type: 'commitment_letter_accept'
    };

    await ProfileService.updateUserExtraDocuments(data);
    await store.dispatch('fetchTenantPermissions', true)
    store.dispatch('acceptCommitmentLetter')
}

onMounted(async () => {
    const responseCL = await TextPageService.textPageBySlug("commitment_letter_general");
    if (responseCL.status == 200) {
        content.value = responseCL.data.data
    }
    loading.value = false
})
</script>

<style lang="scss" scoped>
.content-box {
    height: 450px;
    overflow-y: scroll;
}
</style>