<template>
  <MDBContainer v-if="show">
    <Menu />
    <template v-if="accessData._restricted_access">
      <div class="d-flex justify-content-between align-items-center p-3 mb-2 bg-info bg-gradient text-dark"
        v-if="!accessData._client_type_approved_at">
        {{ $t('onboarding.waitForAdminApproval') }}
      </div>

      <Slider :slides="slides" />
    </template>
    <template v-else>
      <MDBRow>
        <MDBCol col="lg-3" :class="`column-order justify-content-start ${props.switchOrderMobile ? 'order-lg-1 order-2 mt-lg-0 mt-5' : null
          }`">
          <LeftColumnLV />
        </MDBCol>
        <MDBCol col="lg-9" :class="`${props.switchOrderMobile ? 'order-lg-2 order-1' : null}`">
          <props.content />
        </MDBCol>
      </MDBRow>
      <MDBRow class="mt-2">
        <MDBCol col="lg-3">
          <MDBRow v-if="route.name === 'dashboard'">
            <MDBCol class="my-1">
              <h5>&nbsp;</h5>
            </MDBCol>
          </MDBRow>
          <PointBox />
        </MDBCol>
        <MDBCol col="lg-9">
          <props.data />
        </MDBCol>
      </MDBRow>
      <AppsPanel v-if="hasTenantPermission('my-bs-app')" />
    </template>
    <Footer />
  </MDBContainer>

  <MDBModal v-model="showExtraDocuments" tabindex="-1" centered size="xl" class="bg-modal extra-modal-level">
    <ExtraDocumentsModal />
  </MDBModal>

  <MDBModal v-model="commitmentLetterStep" tabindex="-1" centered size="xl" class="bg-modal extra-modal-level">
    <CommitmentLetter />
  </MDBModal>

  <MDBModal v-model="privacyPolicyStep" tabindex="-1" centered size="xl" class="bg-modal extra-modal-level">
    <PrivacyPolicy />
  </MDBModal>

  <MDBModal v-model="showFirstInitialStep" tabindex="-1" centered size="xl" class="bg-modal">
    <PartOne />
  </MDBModal>
  <MDBModal v-model="showSecondInitialStep" tabindex="-1" centered size="xl" class="bg-modal">
    <PartTwo />
  </MDBModal>
  <MDBModal v-model="showThirdInitialStep" tabindex="-1" centered size="xl" class="bg-modal">
    <PartThree />
  </MDBModal>
</template>

<script setup>
import { MDBCol, MDBRow, MDBContainer, MDBModal } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Menu from "@/components/Layout/Menu.vue";
import Footer from "@/components/Layout/Footer.vue";
import LeftColumnLV from "@/components/Layout/LeftColumnLV.vue";
import AppsPanel from "@/components/AppsPanel/AppsPanel.vue";
import PartOne from "@/components/Intro/InitialSurvey/PartOne.vue";
import PartTwo from "@/components/Intro/InitialSurvey/PartTwo.vue";
import PartThree from "@/components/Intro/InitialSurvey/PartThree.vue";
import PrivacyPolicy from "@/components/Intro/InitialSurvey/PrivacyPolicy.vue";
import CommitmentLetter from "@/components/Intro/InitialSurvey/CommitmentLetter.vue";
import ExtraDocumentsModal from "@/components/Intro/InitialSurvey/ExtraDocumentsModal.vue";
import PointBox from "@/components/Dashboard/PointBox.vue";
import Slider from "@/components/Dashboard/Slider.vue";
import { defineProps, ref, computed, onMounted } from "vue";
import { useTenantConfig } from "@/composables/useTenantConfig";
import { useAllowOnboard } from "@/composables/useProfileFieldCheck";

const store = useStore();
const route = useRoute();
const { tenantConfig } = useTenantConfig();
const { allowOnboard } = useAllowOnboard(tenantConfig);

const props = defineProps({
  content: Object,
  switchOrderMobile: Boolean,
  data: Object,
});

const user = computed(() => store.getters.getMe);
const initialStep = computed(() => store.getters.getInitialSurveyStep);
const isFirstInitialStep = computed(() => initialStep.value === 1);
const isSecondInitialStep = computed(() => initialStep.value === 2);
const isThirdInitialStep = computed(() => initialStep.value === 3);
const meIsPending = computed(() => store.getters.getIsMePending);
const accessData = computed(() => store.getters.getAccessData);
const slides = computed(() => store.getters.getUserSlides);

const show = ref(false); // Used to control UI state

const showExtraDocuments = computed(() => user.value && store.getters.getShowExtraDocumentModal);

const checkPrivacyAndCommitment = computed(() => accessData.value && (
  (accessData.value._restricted_country && accessData.value._commitment_letter_accept && accessData.value._privacy_accepted_at)
  ||
  (accessData.value._restricted_country && (!accessData.value._commitment_letter_accept || !accessData.value._privacy_accepted_at))
  ||
  (!accessData.value._restricted_country && !accessData.value._commitment_letter_accept && !accessData.value._privacy_accepted_at)
)
)

const commitmentLetterStep = computed(() => user.value && store.getters.getShowCommitmentLetterModal);
const privacyPolicyStep = computed(() => user.value && store.getters.getShowPrivacyPolicyModal);
// Computed properties for survey modals
const showFirstInitialStep = computed(() => checkPrivacyAndCommitment.value && isFirstInitialStep.value && allowOnboard());
const showSecondInitialStep = computed(() => checkPrivacyAndCommitment.value && isSecondInitialStep.value && allowOnboard());
const showThirdInitialStep = computed(() => checkPrivacyAndCommitment.value && isThirdInitialStep.value && allowOnboard());

onMounted(() => {
  if (!meIsPending.value) {
    store.dispatch("me")
      .finally(() => {
        show.value = true; // Ensures UI updates after API call
      });
  } else {
    show.value = false; // If already pending, set as false immediately
  }
});
</script>


<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.bg-modal {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.extra-modal-level {
  z-index: 1056 !important;
}
</style>
'