<template>
    <Loader :show="loading" v-if="loading" />
    <div class="px-5 py-5 bg-gray" v-else>
        <div v-if="accessData">
            <MDBAccordion v-model="activeItem" stayOpen>
                <MDBAccordionItem :headerTitle="contentCL.title[storedLocale]" collapseId="contentCL-collapse">
                    <div class="bg-white px-3 py-3 content-box" ref="contentBox" v-on:scroll="handleScroll($event, 1)">
                        <div class="pb-2" v-html="contentCL.description[storedLocale]"></div>
                        <div v-if="contentCL.external_link[storedLocale]">
                            <a :href="contentCL.external_link[storedLocale]" target="_blank">
                                {{ $t("global.buttons.download") }}</a>
                        </div>
                    </div>
                </MDBAccordionItem>
                <MDBAccordionItem :headerTitle="contentPP.title[storedLocale]" collapseId="contentPP-collapse">
                    <div class="bg-white px-3 py-3 content-box" ref="contentBox" v-on:scroll="handleScroll($event, 2)">
                        <div class="pb-2" v-html="contentPP.description[storedLocale]"></div>
                        <div v-if="contentPP.external_link[storedLocale]">
                            <a :href="contentPP.external_link[storedLocale]" target="_blank">
                                {{ $t("global.buttons.download") }}</a>
                        </div>
                    </div>
                </MDBAccordionItem>
            </MDBAccordion>

            <MDBCardFooter class="px-0 d-flex justify-content-between pt-4">
                <MDBBtn class="d-flex justify-content-center align-items-center" color="danger" :disabled="disableBtn"
                    @click="accept()"><span>{{ $t("global.buttons.save") }}</span>
                </MDBBtn>
            </MDBCardFooter>
        </div>
    </div>
</template>

<script setup>
import {
    MDBBtn,
    MDBCardFooter, MDBAccordion, MDBAccordionItem
} from "mdb-vue-ui-kit";
import Loader from "@/components/General/Loader/index.vue";
import { useStore } from "vuex";
import { computed, ref, onMounted } from "vue";
import { TextPageService } from "@/services/textPage.service";
import { ProfileService } from "@/services/profile.service";

const disableBtn = ref(true)
const loading = ref(true)
const contentCL = ref(null)
const contentPP = ref(null)
const isFirstRead = ref(false)
const isSecondRead = ref(false)
const activeItem = ref('contentCL-collapse');

const store = useStore();

const accessData = computed(() => store.getters.getAccessData);
const storedLocale = computed(() => store.getters.getCurrentLocale);

const accept = async () => {
    disableBtn.value = true
    await ProfileService.approveExtraDocuments()
    await store.dispatch('fetchTenantPermissions', true)
    // approve commitment letter
    store.dispatch('acceptExtraDocuments')
    store.commit('SET_SHOW_EXTRA_DOCUMENTS_MODAL', false)
}

const handleScroll = (event, type) => {
    console.log(event)
    if (
        Math.round(event.srcElement.offsetHeight + event.srcElement.scrollTop) +
        2 >=
        event.srcElement.scrollHeight && type === 1
    ) {
        isFirstRead.value = true
        activeItem.value = "contentPP-collapse"
    }

    if (
        Math.round(event.srcElement.offsetHeight + event.srcElement.scrollTop) +
        2 >=
        event.srcElement.scrollHeight && type === 2
    ) {
        isSecondRead.value = true
    }

    if (isFirstRead.value && isSecondRead.value) {
        disableBtn.value = false;
    }
};

onMounted(async () => {
    const responseCL = await TextPageService.textPageBySlug("commitment_letter_indirect");
    const responsePP = await TextPageService.textPageBySlug("privacy_policy_indirect");

    if (responseCL.status == 200) {
        contentCL.value = responseCL.data.data
    }

    if (responsePP.status == 200) {
        contentPP.value = responsePP.data.data
    }

    loading.value = false
})
</script>

<style lang="scss" scoped>
.content-box {
    height: 450px;
    overflow-y: scroll;
}
</style>