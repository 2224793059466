<template>
    <div class="px-5 py-5 bg-gray">
        <h1 v-html="$t('onboarding.title')"></h1>
        <div class="pb-2">
            {{ $t('onboarding.bussinessType') }}
        </div>
        <h5 class="w-100 text-end pb-2 text-uppercase">{{ $t('onboarding.stage') }} 3/3</h5>
        <MDBCard>
            <MDBCardBody>
                <div>{{ $t('onboarding.businessRelationship') }} </div>
                <div>{{ $t('onboarding.selectRelationship') }}</div>
                <div class="py-1"><b> {{ $t('onboarding.tradingPartner') }}...</b></div>
                <div>
                    <input type="radio" class="form-check-input" name="tradingPartnerDirect"
                        value="tradingPartnerDirect" v-model="bussinessPartner" />
                    <label for="tradingPartnerDirect" class="form-check-label">{{
                        $t('onboarding.tradingPartnerDirect')
                    }}</label>
                </div>
                <div>
                    <input type="radio" class="form-check-input" name="tradingPartnerIndirect"
                        value="tradingPartnerIndirect" v-model="bussinessPartner" />
                    <label for="tradingPartnerIndirect" class="form-check-label">{{
                        $t('onboarding.tradingPartnerIndirect')
                    }} </label>
                </div>
                <div v-if="cannotSeeBothRelation">
                    <input type="radio" class="form-check-input" name="tradingPartnerBoth" value="tradingPartnerBoth"
                        v-model="bussinessPartner" />
                    <label for="tradingPartnerBoth" class="form-check-label">{{
                        $t('onboarding.tradingPartnerBoth')
                        }} </label>
                </div>

                <div
                    v-if="bussinessPartner === 'tradingPartnerDirect' || bussinessPartner === 'tradingPartnerBoth' || bussinessPartner === 'fleetResponsibleDirect' || bussinessPartner === 'fleetResponsibleBoth'">
                    <div class="py-1"><b>{{ $t('account.profile.bsClientNr') }}<sup>*</sup>:</b></div>
                    <div><input type="text" pattern="[0-9]" required minlength="6" maxlength="10"
                            :placeholder="$t('onboarding.enterCustomerNumber')" v-model="customerNumber" />
                    </div>
                    <div class="text-danger" v-if="errorMessage">{{ $t("global.validation.requiredField") }}. {{
                        $t('onboarding.lengthCsNumber') }}.</div>
                </div>

                <div v-if="cannotSeeFleet">
                    <div class="py-3"><b> {{ $t('onboarding.fleetResponsibleDirect') }}</b></div>
                    <div>
                        <input type="radio" class="form-check-input" name="fleetResponsibleDirect"
                            value="fleetResponsibleDirect" v-model="bussinessPartner" />
                        <label for="fleetResponsibleDirect" class="form-check-label">{{
                            $t('onboarding.tradingPartnerDirect')
                            }}</label>
                    </div>
                    <div>
                        <input type="radio" class="form-check-input" name="fleetResponsibleIndirect"
                            value="fleetResponsibleIndirect" v-model="bussinessPartner" />
                        <label for="fleetResponsibleIndirect" class="form-check-label">{{
                            $t('onboarding.fleetResponsibleIndirect') }}
                        </label>
                    </div>
                    <div v-if="cannotSeeBothRelation">
                        <input type="radio" class="form-check-input" name="fleetResponsibleBoth"
                            value="fleetResponsibleBoth" v-model="bussinessPartner" />
                        <label for="fleetResponsibleBoth" class="form-check-label">{{
                            $t('onboarding.fleetResponsibleBoth')
                            }}</label>
                    </div>
                </div>

                <div class="py-3" v-if="cannotSeeNoBussinessRelation">
                    <input type="radio" class="form-check-input" value="noBusinessRelationship"
                        v-model="bussinessPartner" />
                    <label for="sachschaden1" class="form-check-label"
                        v-html="$t('onboarding.noBusinessRelationship')"></label>
                </div>
            </MDBCardBody>
        </MDBCard>

        <div class="w-100 d-flex justify-content-between mt-5 mb-1">
            <div>{{ $t('onboarding.required') }}</div>
            <div class="d-flex">
                <MDBBtn @click="goBack()" color="danger">{{ $t("global.buttons.back") }}</MDBBtn>
                <MDBBtn type="submit" color="danger" class="d-flex justify-content-center align-items-center"
                    :disabled="loading || !bussinessPartner" @click="onSubmit()">
                    <span>{{ $t('global.buttons.save') }}</span>
                </MDBBtn>
            </div>

        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { MDBCardBody, MDBCard, MDBBtn } from "mdb-vue-ui-kit";
import { UserService } from "@/services/user.service";
import { GroupService } from "@/services/group.service";
import { ProfileService } from "@/services/profile.service";
import { useTenantConfig } from "@/composables/useTenantConfig";
import { useAllowDuplicatedCustomerNumber } from "@/composables/useProfileFieldCheck";

const store = useStore();
const { tenantConfig } = useTenantConfig();
const { allowDuplicatedCustomerNumber } = useAllowDuplicatedCustomerNumber(tenantConfig);
const bussinessPartner = ref(null)
const customerNumber = ref(null)
const me = computed(() => store.getters.getMe);
const initialParentAttributes = computed(() => store.getters.getInitialParentAttributes);
const loading = ref(false)
const errorMessage = ref(false)
const selections = ref([])

const storedLocale = localStorage.getItem("currentLocale");
const cannotSeeBothRelation = computed(() => {
    const notAvailableCodes = store.getters.bothRelationOptionDisabled
    const currentCountry = storedLocale.split('-')

    return !notAvailableCodes.includes(currentCountry[1])
})

const cannotSeeFleet = computed(() => {
    const notAvailableCodes = store.getters.fleetRelationOptionDisabled
    const currentCountry = storedLocale.split('-')

    return !notAvailableCodes.includes(currentCountry[1])
})

const cannotSeeNoBussinessRelation = computed(() => {
    const notAvailableCodes = store.getters.noBussinesRelationOptionDisabled
    const currentCountry = storedLocale.split('-')

    return !notAvailableCodes.includes(currentCountry[1])
})

const goBack = async () => {
    const data = {
        id: me.value.id,
        intro: 2,
    };
    await ProfileService.updateUserLiteIntro(data);
    await store.dispatch("me");
}

const onSubmit = async () => {
    let showCsnumber = bussinessPartner.value === 'tradingPartnerDirect' ||
        bussinessPartner.value === 'tradingPartnerBoth' ||
        bussinessPartner.value === 'fleetResponsibleDirect' ||
        bussinessPartner.value === 'fleetResponsibleBoth'

    const isDigit = /^\d+$/.test(customerNumber.value)
    const lengthCheck = customerNumber.value && (customerNumber.value.length < 6 || customerNumber.value.length > 10)

    if ((!customerNumber.value && showCsnumber) || (!isDigit && customerNumber.value) || lengthCheck) {
        errorMessage.value = true
    } else {
        errorMessage.value = false
        loading.value = true
        if (customerNumber.value && showCsnumber) {
            const csNrData = {
                expected_name: 'Bridgestone',
                customer_number: customerNumber.value,
                dealer_id: me.value.plainId,
                id: null,
                can_be_duplicated: allowDuplicatedCustomerNumber()
            };

            await UserService.saveCustomerNumber(csNrData);
        }

        selections.value.push(bussinessPartner.value)

        store.dispatch('addInitialParentAttributes', {
            user_id: me.value.id,
            options: initialParentAttributes.value.options,
            selection: selections.value
        });

        let input = {
            user_id: me.value.plainId,
            options: initialParentAttributes.value.options,
            selection: initialParentAttributes.value.selection
        }

        await GroupService.parentAttributeAsignSelector(input)
        const data = {
            id: me.value.id,
            intro: 0,
        };
        await ProfileService.updateUserLiteIntro(data);
        await store.dispatch("loadDealerApps");
        await store.dispatch("me");
        await store.dispatch('fetchTenantPermissions', true)
        await store.dispatch("loadUserSlides");
        loading.value = false

        store.dispatch('addInitialParentAttributes', []);
        store.dispatch('displayInitialSurvey', 0)

    }
}
</script>